import * as React from 'react'
import { CoachHeader } from '../CoachHeader'
import HeadShot from './HeadShot'
import Accolade from './Accolade'

export default function MeetYourCoach({ coach, coach_photo, coach_bio }) {
  return (
    <div className="m-12 flex flex-col gap-10 font-roboto">
      <div className="flex flex-col gap-6">
        <CoachHeader
          title="Meet Your Personal Coach"
          description="Learn about your coach and their history."
        />

        <div className="grid grid-cols-1 gap-6 xl:grid-cols-3 2xl:grid-cols-[340px_repeat(3,_minmax(0,_1fr))]">
          <div className="flex xl:col-span-3 2xl:col-span-1 2xl:row-span-2">
            <div className="flex flex-col items-center">
              <HeadShot
                className="h-[250px] w-[250px]"
                coachImage={coach_photo}
              />
              <h2 className="block bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright bg-clip-text text-center font-ibm-plex text-5xl font-bold uppercase text-transparent">
                {coach.first_name + ' ' + coach.last_name}
              </h2>
              <p className="text-center text-2xl font-medium text-[#4a4a4a]">
                Your Dedicated Career Coach
              </p>
            </div>
          </div>
          <Accolade
            rating="16+"
            title="Years Experience"
            description={`${
              coach.first_name + ' ' + coach.last_name
            } brings over 16 years of career development and human resources experience to Intoo USA.`}
          />
          <Accolade
            rating="100+"
            title="Clients Supported"
            description={`${coach.first_name} is a member of the coaching team at Intoo USA that recently won the Stevie Gold Award, a national award received for providing exceptional coaching. `}
          />
          <Accolade
            rating="97%"
            title="Satisfaction Rating"
            description={`${coach.first_name} is a member of the coaching team at Intoo USA that recently won the Stevie Gold Award, a national award received for providing exceptional coaching. `}
          />
          <div className="border-t border-cod-gray-200 pt-6 xl:col-span-3">
            <div className="rounded-3xl bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright px-24 py-10 font-roboto-serif text-lg italic text-white">
              &ldquo;A dedicated person who sees hidden potential and maximizes
              performance by encouraging, developing and believing.&rdquo;
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <h2 className="text-3.5xl font-bold text-intoo-green-coachcta">
            About {coach.first_name}:
          </h2>
          <p>{coach_bio}</p>
        </div>
      </div>
    </div>
  )
}

function SemiCircle() {
  return (
    <svg
      width="225"
      height="143"
      viewBox="0 0 225 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.737732 59.9164C8.35945 88.4997 27.24 112.797 53.2259 127.463C79.2117 142.128 110.174 145.961 139.302 138.119C168.43 130.276 193.337 111.399 208.544 85.6423C223.751 59.8851 228.013 29.3569 220.391 0.773541L110.564 30.345L0.737732 59.9164Z"
        fill="url(#paint0_linear_2313_11200)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2313_11200"
          x1="1.49573"
          y1="12.6548"
          x2="191.845"
          y2="-37.5384"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1D7029" />
          <stop offset="1" stop-color="#75B21D" />
        </linearGradient>
      </defs>
    </svg>
  )
}
