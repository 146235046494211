import React from 'react'
import { cn } from '../../lib/utils'
import NavLink from './NavLink'
import { useNav } from './NavProvider'

const NavGroup = ({ section }) => {
  const { isExpanded, meetsMinimumProgramLevel } = useNav()
  const className = isExpanded ? '' : 'items-center'
  const containerClasses = cn('flex flex-col gap-2', className)
  const { theme, hideCollapsed, programLevel } = section
  const extraClasses = theme == 'green' ? 'text-intoo-green-coachcta' : ''
  if (
    !meetsMinimumProgramLevel(programLevel) ||
    (!isExpanded && hideCollapsed)
  ) {
    return null
  }
  const collapsedTitle = section.collapsedTitle || section.title
  const showDivider = section.showDivider === false ? false : true
  return (
    <>
      {showDivider && <NavDivider />}
      <div className={containerClasses}>
        <NavGroupHeader className={extraClasses}>
          {isExpanded ? section.title : collapsedTitle}
        </NavGroupHeader>
        {section.children.map(
          (item, index) =>
            !item.hidden && <NavLink key={index} item={item} theme={theme} />,
        )}
      </div>
    </>
  )
}
export default NavGroup

const NavDivider = ({ className }) => {
  const classes = cn('my-6 h-[0.125rem] rounded-sm bg-cod-gray-50', className)
  return <div className={classes}></div>
}

const NavGroupHeader = ({ className, children }) => {
  const { isExpanded } = useNav()
  const expandedClasses = isExpanded ? '' : 'px-0 text-[10px]'
  const headerClasses = cn(
    'w-11 text-center px-3 py-2 text-sm font-medium uppercase tracking-wide text-intoo-blue-medium',
    expandedClasses,
    className,
  )
  return <div className={headerClasses}>{children}</div>
}
