import React, { useEffect, useState } from 'react'
import { No, Yes } from './Icons/YesNo'
import Option from './Option'
import { useQuiz } from './QuizProvider'
import { cn } from '../../lib/utils'

const LayoutTwoOptions = ({
  options,
  updateResponse,
  questionInfo,
  children,
}) => {
  // const focusIndex = trackContent[quizIndex + 1] === 'a' ? 0 : 1
  const { trackContent, setTrackContent, tasks, setTasks, quizIndex } =
    useQuiz()
  const [focus, setFocus] = useState()
  const [hover, setHover] = useState()
  useEffect(() => {
    if (trackContent[quizIndex + 1] === 'a') {
      setFocus(0)
    } else if (trackContent[quizIndex + 1] === 'b') {
      setFocus(1)
    }
  }, [focus, trackContent, quizIndex])

  const handleOptionClick = (choice) => {
    const show = () => {
      updateResponse(choice)
    }

    if (choice === 0) {
      trackContent[quizIndex + 1] = 'a'
    } else if (choice === 1) {
      trackContent[quizIndex + 1] = 'b'
    } else if (choice === 2) {
      trackContent[quizIndex + 1] = 'c'
    }

    setTrackContent({
      ...trackContent,
    })

    let newTask
    const prevTasks = tasks

    if (choice === 0 && questionInfo.yes_action_results_copy) {
      newTask = questionInfo.yes_action_results_copy
    } else if (choice === 1 && questionInfo.no_action_results_copy) {
      newTask = questionInfo.no_action_results_copy
    } else if (questionInfo.action_results_copy) {
      newTask = questionInfo.action_results_copy
    }

    if (newTask) {
      prevTasks[quizIndex + 1] = newTask
      setTasks({ ...prevTasks })
    }

    show()
  }

  return (
    options.length == 2 && (
      <div className="z-10 justify-between sm:flex">
        <div className="question-options flex flex-col gap-6 sm:gap-8">
          {options.map((option, index) => (
            <Option
              key={`response-${index}`}
              id={`btn-${quizIndex + 1}-${index}`}
              className={cn(
                'h-32 sm:w-[350px]',
                focus === index ? 'selected' : '',
              )}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(null)}
              onClick={() => {
                handleOptionClick(index)
                setFocus(index)
              }}
            >
              <div className="flex flex-col items-center gap-2">
                {index === 0 ? (
                  <Yes white={focus === 0 || hover === 0} />
                ) : (
                  <No white={focus === 1 || hover === 1} />
                )}
                <div className="break-words">{option}</div>
              </div>
            </Option>
          ))}
        </div>
        {children}
      </div>
    )
  )
}
export default LayoutTwoOptions
