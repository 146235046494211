import React, { createContext, useContext, useState, useEffect } from 'react'

const QuizContext = createContext()

export const useQuiz = () => {
  return useContext(QuizContext)
}

export const QuizProvider = ({
  startPageAvatars,
  resultsPageAvatars,
  quizId,
  quizPartId,
  quizName,
  quizTime,
  content,
  bucketPath,
  children,
  numChoices,
}) => {
  const [started, setStarted] = useState(
    (quizPartId && localStorage.getItem(`${quizPartId}:started`) === 'true') ||
      false,
  )
  const [showResults, setShowResults] = useState(
    (quizPartId && localStorage.getItem(`${quizPartId}:results`) === 'true') ||
      false,
  )

  const getSlugs = () => {
    if (content) return content.slugs
  }

  const getResultsTasks = () => {
    if (showResults) {
      return content?.tasks
    }
  }
  const tempSlugs = getSlugs()
  const tempResults = getResultsTasks()

  const [tasks, setTasks] = useState(content?.tasks || {})
  const [slugs, setSlugs] = useState(tempSlugs ? new Set(tempSlugs) : new Set())
  const [documentId, setDocumentId] = useState(quizId)
  const [documentPartId, setDocumentPartId] = useState(quizPartId)
  const [pageRenderedOnce, setPageRenderedOnce] = useState(false)
  const [trackContent, setTrackContent] = useState(content?.content || {})
  const [quizIndex, setQuizIndex] = useState(0)
  const formattedQuizName = quizName.toLowerCase().split(' ').join('_')
  const [resultsTasks, setResultsTasks] = useState(tempResults || [])

  const randomStartAvatar =
    startPageAvatars[Math.floor(Math.random() * startPageAvatars.length)]

  const randomeResultsAvatar =
    resultsPageAvatars[Math.floor(Math.random() * resultsPageAvatars.length)]

  const startPageAvatar = bucketPath + randomStartAvatar

  const resultsPageAvatar = bucketPath + randomeResultsAvatar

  const updateUserTracker = async () => {
    const reqBody = {
      tracker_sections_slugs: Array.from(slugs),
    }
    const token = document.querySelector('meta[name="csrf-token"]').content
    const res = await fetch(`/trackers/${tracker.id}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-TOKEN': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    })
    if (res.ok) return res
    throw new Error('res not ok')
  }

  const actions = (questionNumber, answerType) => {
    const prevState = slugs
    switch (parseInt(questionNumber)) {
      case 1:
        if (answerType === 0) {
          prevState.delete('resume-builder')
          // setSlugs(new Set([...prevState, 'resume-review']));
          setSlugs(new Set([...prevState]))
        } else setSlugs((prev) => new Set([...prev, 'resume-webinar']))
        break
      case 2:
        prevState.add('linked-in-guide')
        setSlugs(new Set([...prevState]))
        break
      // setSlugs(prev => new Set([...prev, 'linked-in-guide']))
      case 3:
        prevState.add('cover-letter-builder')
        setSlugs(new Set([...prevState]))
        break
      case 4:
        break
    }
  }

  useEffect(() => {
    if (!showResults) {
      const handleReload = (e) => {
        if (started) {
          e.preventDefault()
          e.returnValue = ''
        }
      }
      window.addEventListener('beforeunload', handleReload)
      return () => {
        window.removeEventListener('beforeunload', handleReload)
      }
    }
  }, [started, showResults])

  useEffect(() => {
    if (quizPartId) {
      localStorage.setItem(`${quizPartId}:started`, started)
      localStorage.setItem(`${quizPartId}:results`, showResults)
    }
  }, [started, showResults, quizIndex, quizPartId])

  useEffect(() => {
    if (!pageRenderedOnce && Object.keys(trackContent).length > 0) {
      setQuizIndex(Object.keys(trackContent).length - 1)
    }
    return () => {
      setPageRenderedOnce(true)
    }
  }, [trackContent, pageRenderedOnce])

  const saveQuiz = async (results = false) => {
    const token = document.querySelector('meta[name="csrf-token"]').content

    const url = documentId
      ? `/documents/${documentId}/parts/${documentPartId}`
      : `/quiz/document/quizzes?quiz_type=${formattedQuizName}`

    if (!documentId) {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
      if (res.ok) {
        setStarted(true)
        const data = await res.json()
        setDocumentId(data.document_id)
        setDocumentPartId(data.document_part_id)
        return res
      }
    } else {
      const documentPartsInfo = { content: { content: trackContent } }
      documentPartsInfo.content['tasks'] = tasks
      documentPartsInfo.content['slugs'] = Array.from(slugs)
      if (results) {
        setResultsTasks(tasks)
        setTasks([])
      }
      // else {
      //   documentPartsInfo.content['tasks'] = tasks
      //   documentPartsInfo.content['slugs'] = Array.from(slugs)
      // }
      const res = await fetch(url, {
        method: 'PATCH',
        headers: {
          'X-CSRF-TOKEN': token,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(documentPartsInfo),
      })
      if (res.ok) {
        if (results) {
          // updateUserTracker()
          setShowResults(true)
          // calcScore(trackContent)
          document.getElementsByTagName('body')[0].classList.add('quiz-results')
        } else window.location.pathname = '/'
        return res
      }
    }
    throw new Error('res not okay')
  }

  const value = {
    quizPartId,
    documentId,
    setDocumentId,
    documentPartId,
    setDocumentPartId,
    quizName,
    tasks,
    setTasks,
    updateUserTracker,
    actions,
    slugs,
    setSlugs,
    startPageAvatar,
    resultsPageAvatar,
    quizTime,
    content,
    bucketPath,
    pageRenderedOnce,
    setPageRenderedOnce,
    started,
    setStarted,
    showResults,
    setShowResults,
    quizIndex,
    setQuizIndex,
    saveQuiz,
    resultsTasks,
    trackContent,
    setTrackContent,
    formattedQuizName,
    numChoices,
  }

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>
}
