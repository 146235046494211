import React from 'react'
import { ExercisesIcon } from './icons'

const ExerciseCard = ({ exercise }) => {
  const { headerImageUrl, headerImageAlt, path, title, description } = exercise

  return (
    <div className="relative mb-8 overflow-hidden rounded-3xl border border-solid border-[#B9B9B9]">
      <img
        src={headerImageUrl}
        alt={headerImageAlt}
        className="aspect-[22/11] w-full object-cover object-center"
      />
      <div className="p-4">
        <h2
          role="heading"
          tabIndex="0"
          aria-level="2"
          className="!mb-2 !mt-0 truncate text-xl font-bold focus:overflow-visible"
        >
          {title}
        </h2>
        <p className="!my-0 line-clamp-3">{description}</p>
        <div className="mt-6">
          <a
            href={path}
            className="flex w-full items-center justify-center rounded-3xl bg-primary px-4 py-3 text-base font-medium text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white sm:py-2"
          >
            Start Exercise
            <ExercisesIcon className="ml-2 stroke-white" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ExerciseCard
