export const useYesNoQuizScore = (content) => {
  const numAs = Object.values(content).filter((val) => val === 'a').length
  const score = Math.round((numAs / Object.keys(content).length) * 100)
  if (numAs <= 8) {
    return [1, numAs]
  } else if (numAs <= 14) {
    return [2, numAs]
  } else {
    return [3, score]
  }
}

export const useMultipleChoiceQuizScore = (content, questions) => {
  let tempNumCorrect = 0
  const contentKeys = Object.keys(content)
  contentKeys.forEach((key) => {
    const answerChoice = content[key]
    if (questions[key].options[answerChoice].feedback_key === 'yes_response') {
      tempNumCorrect += 1
    }
  })
  const numCorrect = tempNumCorrect
  const score = Math.round((numCorrect / contentKeys.length) * 100)
  const link1 = 'Building a Personal Brand in the Digital Age'
  const link2 = 'Personal Branding in the Digital Age '

  if (numCorrect > 7) {
    return [1, score, link1]
  } else if (numCorrect > 4) {
    return [2, score, link1]
  } else if (numCorrect > 2) {
    return [3, score, link2]
  } else {
    return [4, score, link1]
  }
}
