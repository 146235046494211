import React, { useRef } from 'react'
import { cn } from '../../lib/utils'
import { useNav } from './NavProvider'
import { getIcon } from './icons'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import SubNav from './SubNav'
import PopupSubNav from './PopupSubNav'
import Link from './Link'

const NavLink = ({ item, theme }) => {
  const { activeNav } = useNav()
  const { slug } = item
  const extraClasses =
    theme == 'green'
      ? 'bg-intoo-green-coachcta hover:bg-intoo-green-coachcta-hover'
      : ''
  if (slug === activeNav) {
    return (
      <NavLinkInner active item={item} className={extraClasses} theme={theme} />
    )
  } else {
    return <NavLinkInner item={item} />
  }
}
export default NavLink

const defaultInactiveClasses =
  'text-normal flex cursor-pointer items-center px-3 py-2.5 font-medium leading-5 tracking-tight !text-cod-gray-800 hover:bg-cod-gray-100 rounded-lg hover:border-none focus:outline-none'
const defaultActiveClasses = cn(
  defaultInactiveClasses,
  'bg-intoo-blue-medium hover:border-intoo-blue-medium hover:bg-primary-button-darker-75 !text-white hover:text-white',
)

const NavLinkInner = ({ active, item, className, theme }) => {
  const { navigateTo, setActiveNav, setActiveSubNav, isExpanded } = useNav()
  const { title, children, slug } = item
  const popupRef = useRef(null)
  const Icon = getIcon(slug)

  const activeClasses = cn(defaultActiveClasses, className)
  const inactiveClasses = cn(defaultInactiveClasses, className)
  const handleClick = () => {
    active ? deactivate() : activate()
    navigateTo(item)
  }
  const activate = () => {
    setActiveNav(slug)
    setActiveSubNav(null)
  }
  const deactivate = () => {
    if (children) {
      setActiveNav(null)
      setActiveSubNav(null)
    }
  }
  const collapsedChildren = children ? children : [item]
  if (isExpanded) {
    return (
      <>
        <Link
          className={active ? activeClasses : inactiveClasses}
          onClick={handleClick}
        >
          {Icon && <Icon className="mr-2 inline-block h-6 w-6" />}
          {title}
          {children && (
            <ChevronDownIcon
              className={cn(
                'ml-auto inline-block h-4 w-4 stroke-2 transition-all duration-500',
                active && 'rotate-180',
              )}
            />
          )}
        </Link>
        <SubNav
          items={children}
          theme={theme}
          parentActive={active}
          className={cn(
            'transition-all duration-500',
            active ? 'visible max-h-screen' : 'invisible max-h-0',
          )}
        />
      </>
    )
  } else {
    return (
      <>
        <PopupSubNav
          popupRef={popupRef}
          slug={slug}
          items={collapsedChildren}
        />
        <Link
          className={active ? activeClasses : inactiveClasses}
          onClick={handleClick}
          title={title}
          popupRef={popupRef}
          slug={slug}
        >
          {Icon && <Icon className="inline-block h-6 w-6" />}
        </Link>
      </>
    )
  }
}
