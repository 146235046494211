import * as React from 'react'
import ProgressContainer from '../../ActionPlan/ProgressContainer'
import { DoorIcon } from '../Icons'
import { useTranslation } from '../../../hooks/useTranslation'
import { cn } from '../../../lib/utils'
import { Button } from '../../../lib/ui/button'

export function ActionPlanCard({
  tracker_progress,
  tracker_time_period,
  className,
}) {
  const { t } = useTranslation()

  return (
    <div
      className={cn(
        'relative rounded-[20px] border border-[#B1B1B1] p-6  shadow-md',
        className,
      )}
    >
      <div className="flex h-full flex-col text-cod-gray-800">
        <div className="mb-[24px] flex">
          <div className="flex-grow">
            <h2 className="mb-[16px] text-2xl font-bold text-intoo-blue-medium">
              Action Plan
            </h2>
            <div>
              You're in{' '}
              {`${t(tracker_time_period.split('_')[0])} ${t(
                tracker_time_period.split('_')[1],
              )}`}
            </div>
          </div>
          <ProgressContainer
            progressPercent={tracker_progress}
            homeProgress={true}
          />
        </div>
        <div className="flex flex-col">
          <p className="mb-[24px]">
            Not sure where to start? Click here and get started on the plan we
            created for you!
          </p>
          <div className="absolute bottom-[7%] right-[7%]">
            <Button variant="link" asChild className="h-0 px-0 py-0">
              <a href="orientation/action_plan" className="ga-trackable">
                View Your Weekly Actions
                <DoorIcon className="ml-2 inline-flex" />
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
