import * as React from 'react'

import { CoachCard } from '../CoachCard'
import { CoachButton } from '../CoachButton'
import { LightBulbIcon } from '@heroicons/react/24/outline'

export function CareerConsultationCallCard({ status }) {
  // TODO: Implement in_progress and completed (See ResumeReviewCard.js)
  const disabled = status !== 'unsubmitted'

  return (
    <CoachCard
      icon={LightBulbIcon}
      title="Career Consultation Call"
      description="Schedule an in-depth phone-based career consultation to help you build your career and job target strategy."
    >
      <CoachButton
        className="modal-remote"
        data-toggle="modal"
        data-url="/job-search/career_consultation/new"
        data-key="career-consultation-modal-container"
        disabled={disabled}
        disabledText="Scheduled"
      >
        Schedule A Call
      </CoachButton>
    </CoachCard>
  )
}
