import React from 'react'
import { useDashboard } from './DashboardProvider'
import OrientationCallPopup from '../coach_services/OrientationCallPopup'
import { Button } from '../../lib/ui/button'
import { cn } from '../../lib/utils'
import { IntooColorIcon } from './Icons'
import CoachInfoDialog from './CoachInfoDialog'

const CoachingOverviewCard = ({ className }) => {
  const { isDesktop, coach } = useDashboard()
  return (
    <div
      className={cn(
        'relative flex flex-col gap-2 rounded-[20px] border-[1.5px] border-[#75B21D] p-6 md:justify-between',
        className,
      )}
    >
      {isDesktop && (
        <IntooColorIcon className="absolute right-0 top-0 w-[115px]" />
      )}
      <div className="text-2xl font-medium text-[#1E7129]">
        Coaching Overview
      </div>
      <div className="text-base font-medium">
        {`Schedule your orientation call and get started...Here's why it's
        important`}
      </div>
      <div className="text-base font-medium">
        <ul>
          <li>Lorem ipsum</li>
          <li>Lorem ipsum</li>
          <li>Lorem ipsum</li>
        </ul>
      </div>
      <div
        className={cn('flex flex-col gap-2 mdxl:flex-row mdxl:justify-between')}
      >
        <OrientationCallPopup buttonText={'Schedule Orientation Call'} />
        <CoachInfoDialog coach={coach}>
          <Button variant="outline">
            Get to Know Your{coach.liaison ? ' Liaison ' : ' '}Coach
          </Button>
        </CoachInfoDialog>
      </div>
    </div>
  )
}
export default CoachingOverviewCard
