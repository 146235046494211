import React from 'react'

const DoorIcon = ({ className }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.5 5V2.5C5.5 2.10218 5.65804 1.72064 5.93934 1.43934C6.22064 1.15804 6.60218 1 7 1H11C11.3978 1 11.7794 1.15804 12.0607 1.43934C12.342 1.72064 12.5 2.10218 12.5 2.5V11.5C12.5 11.8978 12.342 12.2794 12.0607 12.5607C11.7794 12.842 11.3978 13 11 13H7C6.60218 13 6.22064 12.842 5.93934 12.5607C5.65804 12.2794 5.5 11.8978 5.5 11.5V9M8 5L10 7M10 7L8 9M10 7H1.5"
        stroke="#1A2B4C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const JobsIcon = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M40.5 28.3V36.8C40.5 38.988 38.926 40.872 36.756 41.16C32.582 41.714 28.324 42 24 42C19.676 42 15.418 41.714 11.244 41.16C9.07399 40.872 7.49999 38.988 7.49999 36.8V28.3M40.5 28.3C40.9749 27.8873 41.3549 27.3767 41.6138 26.8033C41.8727 26.2298 42.0045 25.6072 42 24.978V17.412C42 15.25 40.464 13.382 38.326 13.062C36.0606 12.7228 33.7838 12.4647 31.5 12.288M40.5 28.3C40.112 28.63 39.66 28.89 39.154 29.06C34.2664 30.6817 29.1496 31.5056 24 31.5C18.704 31.5 13.61 30.642 8.84599 29.06C8.35251 28.8958 7.89544 28.6378 7.49999 28.3M7.49999 28.3C7.02508 27.8873 6.64511 27.3767 6.38619 26.8033C6.12726 26.2298 5.99551 25.6072 5.99999 24.978V17.412C5.99999 15.25 7.53599 13.382 9.67399 13.062C11.9394 12.7228 14.2162 12.4647 16.5 12.288M31.5 12.288V10.5C31.5 9.30653 31.0259 8.16193 30.182 7.31802C29.3381 6.47411 28.1935 6 27 6H21C19.8065 6 18.6619 6.47411 17.818 7.31802C16.9741 8.16193 16.5 9.30653 16.5 10.5V12.288M31.5 12.288C26.5074 11.9022 21.4925 11.9022 16.5 12.288M24 25.5H24.016V25.516H24V25.5Z"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const OpenQuoteIcon = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M28 24H38C39.1046 24 40 23.1046 40 22V15C40 13.8954 39.1046 13 38 13H30C28.8954 13 28 13.8954 28 15V24ZM28 24C28 29 30 32 36 35"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 24H18C19.1046 24 20 23.1046 20 22V15C20 13.8954 19.1046 13 18 13H10C8.8954 13 8 13.8954 8 15V24ZM8 24C8 29 10 32 16 35"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

const CloseQuoteIcon = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20 24H10C8.89544 24 8 23.1046 8 22V15C8 13.8954 8.89544 13 10 13H18C19.1046 13 20 13.8954 20 15V24ZM20 24C20 29 18 32 12 35"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M40 24H30C28.8954 24 28 23.1046 28 22V15C28 13.8954 28.8954 13 30 13H38C39.1046 13 40 13.8954 40 15V24ZM40 24C40 29 38 32 32 35"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

const IntooIcon = ({ className }) => {
  return (
    <svg
      width="175"
      height="171"
      viewBox="0 0 175 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M66.1207 82.18C60.8924 76.8201 57.1428 71.3004 54.8922 65.8053C52.6292 60.3609 51.8244 54.9365 52.4295 49.6673C53.0881 43.5354 55.7133 37.6083 60.2 32.1279C62.3175 29.5663 64.9347 28.2827 67.874 30.3746C70.9003 32.5751 69.7334 36.2267 67.4048 39.3148C65.3608 42.0151 63.7877 44.6769 62.6701 47.3693C62.5901 47.4855 62.553 47.6371 62.493 47.798C60.532 52.6765 60.1666 57.5619 61.6211 62.3571C62.8143 66.4442 65.2787 70.4969 69.1451 74.5047C83.1836 89.0802 99.9084 80.5758 96.5732 69.1065C100.581 81.1115 79.206 93.5463 66.1207 82.18Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M45.242 122.796C22.9948 103.269 15.7035 74.379 41.2975 53.8325C44.3833 51.3479 48.0294 49.4335 51.9934 48.05C51.3767 53.3195 52.179 58.7472 54.4473 64.1778C53.326 64.8529 52.2371 65.6271 51.1852 66.4319C50.8029 66.7047 50.4333 66.997 50.0913 67.2992C32.9651 81.2456 29.745 109.992 48.426 125.419C47.3413 124.577 46.2867 123.697 45.242 122.796Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M96.2652 68.0361C96.1604 67.7924 96.0833 67.5587 95.9543 67.3213C90.3422 53.9136 76.1373 47.0277 62.4634 47.5601C62.5204 47.3926 62.5609 47.2317 62.651 47.1214C81.0987 44.9851 101.861 52.645 106.424 69.3708C111.299 87.2204 86.9226 103.523 66.2068 82.1334C79.3204 93.5965 100.663 81.159 96.6247 69.0772C96.6274 68.9879 96.5749 68.8961 96.5383 68.8226C96.4519 68.568 96.3642 68.3104 96.2652 68.0361Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M96.005 67.6234C91.8183 58.9178 75.9834 56.575 61.6625 62.401C60.1856 57.5436 60.5148 52.6239 62.4571 47.7124C76.1366 47.208 90.3612 54.1482 96.005 67.6234Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M119.743 124.188C121.256 130.64 114.679 141.533 100.173 142.261C86.5703 142.94 65.443 138.557 48.6237 125.426L48.6144 125.405C29.8862 109.859 33.0292 80.9857 50.1233 67.016C36.4409 78.4519 35.0738 98.1071 47.2494 108.124C66.402 123.902 100.977 114.419 100.977 114.419C108.856 112.66 117.703 115.46 119.743 124.188Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M158.922 133.09C150.104 138.969 137.919 137.26 131.701 129.276C125.488 121.301 127.598 110.061 136.416 104.182C145.235 98.3065 157.422 100.018 163.637 107.996C169.851 115.982 167.739 127.218 158.922 133.09Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M81.4973 25.6538C78.3114 27.7744 73.9186 27.1599 71.6744 24.2798C69.4301 21.3997 70.1951 17.3438 73.3777 15.2245C76.559 13.1023 80.9565 13.7185 83.2007 16.5986C85.4416 19.48 84.68 23.5346 81.4973 25.6538Z"
        fill="white"
        fillOpacity="0.25"
      />
    </svg>
  )
}

const IntooColorIcon = ({ className }) => {
  return (
    <svg
      width="102"
      height="90"
      viewBox="0 0 102 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M72.0462 20.6381C64.6267 20.9025 58.0771 19.9057 52.5461 17.7627C47.0439 15.6651 42.5298 12.4497 39.0706 8.24964C35.0145 3.39732 32.4214 -2.83685 31.3983 -10.1998C30.9336 -13.6571 31.7395 -16.5976 35.2351 -17.3985C38.8685 -18.1901 40.7668 -14.699 41.4765 -10.7051C42.0919 -7.20651 42.9933 -4.10095 44.2213 -1.32721C44.2532 -1.18284 44.3396 -1.04673 44.4174 -0.886213C46.6851 4.08989 50.023 7.83211 54.5095 10.0977C58.3024 12.0649 62.9187 13.0208 68.4378 12.8565C88.4945 12.2769 93.4233 -6.72765 82.7882 -12.2581C94.265 -6.87061 89.1138 18.5265 72.0462 20.6381Z"
        fill="url(#paint0_linear_2347_19705)"
      />
      <path
        d="M89.3828 66.4053C60.2117 69.85 34.1585 55.0562 36.1765 20.6422C36.4145 16.4877 37.4446 12.302 39.0762 8.24551C42.5287 12.4549 47.0447 15.6749 52.5414 17.7588C52.2878 19.1073 52.1287 20.5006 52.0167 21.887C51.9616 22.3769 51.9292 22.8708 51.9225 23.3502C50.717 46.5183 69.6479 69.3698 93.4322 65.7912C92.0899 66.0364 90.741 66.2322 89.3828 66.4053Z"
        fill="url(#paint1_linear_2347_19705)"
      />
      <path
        d="M81.7961 -12.7154C81.5493 -12.8061 81.3281 -12.9112 81.0695 -12.9785C67.587 -18.0836 53.1302 -11.8938 44.4209 -0.876273C44.3369 -1.03894 44.2469 -1.18417 44.2266 -1.33229C54.9456 -17.1979 74.3275 -27.944 89.525 -19.6787C105.747 -10.8615 101.385 19.6267 72.0503 20.6365C89.1093 18.5298 94.2636 -6.8663 82.7923 -12.2597C82.7292 -12.3249 82.6275 -12.3489 82.5497 -12.3723C82.3071 -12.485 82.0614 -12.5987 81.7961 -12.7154Z"
        fill="url(#paint2_linear_2347_19705)"
      />
      <path
        d="M81.0659 -12.9788C71.9969 -15.8556 59.7968 -5.16759 54.5044 10.1017C50.0143 7.82695 46.6776 4.10083 44.4172 -0.876606C53.1296 -11.893 67.5834 -18.0839 81.0659 -12.9788Z"
        fill="url(#paint3_linear_2347_19705)"
      />
      <path
        d="M139.891 9.59001C145.595 12.9729 149.149 25.7882 140.024 37.5879C131.464 48.6501 114.21 61.9886 93.4537 65.7929L93.4322 65.7854C69.6467 69.3675 50.7158 46.516 51.9225 23.3444C51.1418 42.0711 64.5431 57.0261 79.9406 54.6333C104.177 50.8845 120.287 17.2851 120.287 17.2851C124.25 9.91165 132.178 5.00796 139.891 9.59001Z"
        fill="url(#paint4_linear_2347_19705)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2347_19705"
          x1="85.7519"
          y1="11.4247"
          x2="31.0391"
          y2="-13.0167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00541A" />
          <stop offset="0.319" stopColor="#7DC24B" />
          <stop offset="0.4591" stopColor="#77BD4A" />
          <stop offset="0.6087" stopColor="#6AB346" />
          <stop offset="0.7627" stopColor="#53A441" />
          <stop offset="0.9188" stopColor="#318F3A" />
          <stop offset="1" stopColor="#148335" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2347_19705"
          x1="73.1096"
          y1="-16.8115"
          x2="66.1329"
          y2="68.3364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00152F" />
          <stop offset="0.1168" stopColor="#004260" />
          <stop offset="0.2361" stopColor="#006992" />
          <stop offset="0.3373" stopColor="#0088BA" />
          <stop offset="0.4151" stopColor="#009CD6" />
          <stop offset="0.4601" stopColor="#00A5E5" />
          <stop offset="0.5169" stopColor="#009FDB" />
          <stop offset="0.5919" stopColor="#0093CA" />
          <stop offset="0.6773" stopColor="#0080B0" />
          <stop offset="0.7703" stopColor="#00668E" />
          <stop offset="0.8694" stopColor="#004767" />
          <stop offset="0.9722" stopColor="#00233C" />
          <stop offset="1" stopColor="#00152F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2347_19705"
          x1="40.9012"
          y1="-3.79623"
          x2="102.988"
          y2="4.85306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005C97" />
          <stop offset="0.5" stopColor="#00AAD3" />
          <stop offset="1" stopColor="#005C97" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2347_19705"
          x1="64.243"
          y1="-17.6998"
          x2="57.0895"
          y2="69.6067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00152F" />
          <stop offset="0.5" stopColor="#00AAD3" />
          <stop offset="1" stopColor="#002055" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2347_19705"
          x1="62.4803"
          y1="52.609"
          x2="152.348"
          y2="16.1834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00541A" />
          <stop offset="0.0882" stopColor="#0F6524" />
          <stop offset="0.2364" stopColor="#347F31" />
          <stop offset="0.3871" stopColor="#4E973B" />
          <stop offset="0.5383" stopColor="#62A942" />
          <stop offset="0.6903" stopColor="#70B647" />
          <stop offset="0.8435" stopColor="#79BE4A" />
          <stop offset="1" stopColor="#7DC24B" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export {
  DoorIcon,
  JobsIcon,
  OpenQuoteIcon,
  CloseQuoteIcon,
  IntooIcon,
  IntooColorIcon,
}
