import * as React from 'react'

import { CoachCard } from '../CoachCard'
import { CoachButton } from '../CoachButton'
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from '../../../../hooks/useTranslation'

export function ResumeReviewCard({ status }) {
  const { t } = useTranslation('ResumeReviewCard')
  const tx = t(['review_status', status].join('.'))
  const statuses = {
    unsubmitted: {
      icon: DocumentMagnifyingGlassIcon,
      button: UnsubmittedButton,
    },
    in_progress: {
      icon: DocumentMagnifyingGlassIcon,
      button: InProgressButton,
    },
    completed: {
      icon: DocumentMagnifyingGlassIcon,
      button: CompletedButton,
    },
  }
  const icon = statuses[status].icon
  const Button = statuses[status].button

  return (
    <CoachCard icon={icon} title={tx.title} description={tx.description}>
      <Button>{tx.button_text}</Button>
    </CoachCard>
  )
}

function UnsubmittedButton({ children }) {
  return (
    <CoachButton
      className="modal-remote"
      data-url="/resume/review/new"
      data-key="submit-your-resume-key"
    >
      {children}
    </CoachButton>
  )
}

function InProgressButton({ children }) {
  return (
    <CoachButton variant="outline" asChild>
      <a href="/coach_services/feedback?tab=resume">{children}</a>
    </CoachButton>
  )
}

function CompletedButton({ children }) {
  return (
    <CoachButton variant="default" asChild>
      <a href="/coach_services/feedback?tab=resume">{children}</a>
    </CoachButton>
  )
}
