import React from 'react'
import ArticleCard from './ArticleCard'
import AudioCard from './AudioCard'
import QuizCard from './QuizCard'
import WorkshopCard from './WorkshopCard'
import ExerciseCard from './ExerciseCard'
import VideoCard from './VideoCard'
import NavHeader from './NavHeader'
import ResourcesProvider, { useResources } from './ResourcesProvider'

const ArticleCards = () => {
  const { articles, favoritesActive, checkFavorite } = useResources()
  const displayArticles = favoritesActive
    ? articles.filter(checkFavorite)
    : articles
  return (
    <>
      {displayArticles.map((article) => (
        <ArticleCard key={article.id} article={article} />
      ))}
    </>
  )
}

const QuizCards = () => {
  const { quizzes } = useResources()
  return (
    <>
      {quizzes.map((quiz, index) => (
        <QuizCard key={index} quiz={quiz} />
      ))}
    </>
  )
}

const ExerciseCards = () => {
  const { exercises } = useResources()
  return (
    <>
      {exercises.map((exercise, index) => (
        <ExerciseCard key={index} exercise={exercise} />
      ))}
    </>
  )
}

const AssessmentCards = () => {
  return
}

const AudioCards = () => {
  const { audioFiles } = useResources()
  return (
    <>
      {audioFiles.map((audioFile, index) => (
        <AudioCard key={index} audioFile={audioFile} />
      ))}
    </>
  )
}

const WorkshopCards = () => {
  const { workshops } = useResources()
  return (
    <>
      {workshops.map((workshop, index) => (
        <WorkshopCard key={index} workshop={workshop} />
      ))}
    </>
  )
}

const VideoCards = () => {
  const { videos } = useResources()
  return (
    <>
      {videos.map((video, index) => (
        <VideoCard key={index} video={video} />
      ))}
    </>
  )
}

const ResourcesInner = () => {
  const {
    articlesActive,
    audioActive,
    exercisesActive,
    quizzesActive,
    assessmentsActive,
    workshopsActive,
    favoritesActive,
  } = useResources()

  return (
    <div className="tw-enable min-h-[70vh] font-[Roboto] lg:flex lg:flex-row lg:justify-around">
      <div className="mx-2 max-w-5xl sm:mx-8">
        <h1 className="mb-4 text-2xl font-medium text-black">
          Resource Center
        </h1>
        <div className="mb-6">
          Use these articles and the exercise to perfect your resume before
          sending it out.
        </div>
        <NavHeader />
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-20">
          {articlesActive && <ArticleCards />}
          {audioActive && (
            <>
              <AudioCards />
              <VideoCards />
            </>
          )}
          {exercisesActive && <ExerciseCards />}
          {quizzesActive && <QuizCards />}
          {assessmentsActive && <AssessmentCards />}
          {workshopsActive && <WorkshopCards />}
          {favoritesActive && <ArticleCards />}
          <div className="min-w-[472px] overflow-hidden"></div>
        </div>
      </div>
    </div>
  )
}

const Resources = ({ ...props }) => {
  return (
    <ResourcesProvider {...props}>
      <ResourcesInner />
    </ResourcesProvider>
  )
}

export default Resources
