import React from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../lib/ui/select'

const SetTimelineForm = ({ setNewTimeline }) => {
  const timelineKeys = {
    1: 'In one month',
    2: 'In two months',
    3: 'In three months',
    4: 'In four months',
    5: 'In five months',
    6: 'In six months',
    7: 'In seven months',
    8: 'In eight months',
    9: 'In nine months',
    10: 'In ten months',
    11: 'In eleven months',
    12: 'In twelve months',
    13: 'In thirteen months',
    14: 'In fourteen months',
    15: 'In fifteen months',
    0: 'I have no target date',
  }

  return (
    <Select onValueChange={(v) => setNewTimeline(v)}>
      <SelectTrigger>
        <SelectValue placeholder="Select an option" />
      </SelectTrigger>
      <SelectContent>
        {Object.entries(timelineKeys).map(([key, value]) => (
          <SelectItem key={key} value={key}>
            {value}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
export default SetTimelineForm
