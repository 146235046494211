import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogClose,
} from '../../lib/ui/dialog'
import { useDashboard } from './DashboardProvider'
import Accolade from '../coach_services/MeetYourCoach/Accolade'
import HeadShot from '../coach_services/MeetYourCoach/HeadShot'
import { Button } from '../../lib/ui/button'

const CoachInfoDialog = ({ children, coach }) => {
  const { showCoachInfoDialog, toggleShowCoachInfoDialog } = useDashboard()
  const bio = coach.bio.bio ? coach.bio.bio : coach.bio
  return (
    <Dialog open={showCoachInfoDialog} onOpenChange={toggleShowCoachInfoDialog}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent className="z-10 h-[95%] max-w-sm overflow-y-scroll text-left md:mb-8 md:mt-4 md:h-auto md:max-w-5xl">
        <DialogHeader>
          <div className="m-4 flex flex-col font-roboto text-cod-gray-800">
            <div className="flex flex-col gap-[16px]">
              <div className="flex flex-col md:mb-6 md:grid md:grid-cols-12 md:flex-row">
                <div className="mt-[-32px] flex flex-row items-center md:col-span-4 md:flex-col md:pr-6">
                  <HeadShot
                    className="h-[150px] w-[150px]"
                    coachImage={coach.image}
                  />
                  <h2 className="block shrink-[5] bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright bg-clip-text font-ibm-plex text-3xl font-bold uppercase text-transparent md:text-center">
                    {coach.name}
                  </h2>
                </div>
                <div className="flex flex-col text-left md:col-span-8 md:flex-row">
                  <Accolade
                    rating="16+"
                    title="Years Experience"
                    description={`${coach.name} brings over 16 years of career development and human resources experience to Intoo USA.`}
                  />
                  <Accolade
                    rating="100+"
                    title="Clients Supported"
                    description={`${
                      coach.name.split(' ')[0]
                    } is a member of the coaching team at Intoo USA that recently won the Stevie Gold Award, a national award received for providing exceptional coaching.`}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-[16px] border-t border-cod-gray-200 pt-6 text-left">
                <h2 className="text-2xl font-bold text-intoo-green-coachcta">
                  About {coach.name.split(' ')[0]}:
                </h2>
                <p>{bio}</p>
              </div>
            </div>
          </div>
        </DialogHeader>
        <DialogClose>
          {' '}
          <Button>Close</Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  )
}
export default CoachInfoDialog
