import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  createContext,
} from 'react'
import { getApi, updateCsrfToken } from '../../util/api'

const ArticleContext = createContext()

export const useArticle = () => {
  return useContext(ArticleContext)
}

export default function ArticleProvider({
  article_content,
  has_achievement,
  is_favorite,
  highlight_list,
  comments,
  s3_base_url,
  is_clifton_strengths,
  children,
}) {
  const [jsonContent, setJsonContent] = useState(article_content)
  const sectionRef = useRef(null)
  const [maxScrollDepth, setMaxScrollDepth] = useState(
    has_achievement ? 100 : 0,
  )
  const [hasNotifiedViewed, setHasNotifiedViewed] = useState(has_achievement)
  const [isHighlightModeActive, setIsHighlightModeActive] = useState(false)
  const [isCommentModeActive, setIsCommentModeActive] = useState(false)
  const [highlightList, setHighlightList] = useState(highlight_list || [])
  const [commentsList, setCommentsList] = useState(comments || {})
  const [isFavorite, setIsFavorite] = useState(!!is_favorite)
  const [navTopScrolled, setNavTopScrolled] = useState(false)
  const [audioActive, setAudioActive] = useState(false)
  const navPaddingRef = useRef(null)
  const leftPanelRef = useRef(null)
  const cliftonStrengths = is_clifton_strengths

  const completionAnimationUrl =
    s3_base_url + '/articles/images/article-complete.gif'
  const heroAwsUrl = s3_base_url + jsonContent.heroImage?.awsPath
  const audioAwsUrl = jsonContent.audio
    ? s3_base_url + jsonContent.audio?.awsPath
    : null

  const cacheCompletionAnimation = useCallback(async () => {
    if (!completionAnimationUrl) return
    const completionAnimation = new Image()
    completionAnimation.src = completionAnimationUrl
  }, [completionAnimationUrl])

  useEffect(() => {
    cacheCompletionAnimation()
  }, [cacheCompletionAnimation])

  const jumpToSection = useCallback(
    (index) => {
      sectionRef.current.children[index].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    },
    [sectionRef],
  )

  const toggleAudioActive = () => {
    setAudioActive(!audioActive)
  }

  const toggleHighlightMode = () => {
    setIsCommentModeActive(false)
    setIsHighlightModeActive(!isHighlightModeActive)
  }

  const toggleCommentMode = () => {
    setIsHighlightModeActive(false)
    setIsCommentModeActive(!isCommentModeActive)
  }

  const updateHighlightList = (locator, isHighlighted) => {
    const newHighlightList = isHighlighted
      ? [...highlightList, locator].sort()
      : highlightList.filter((item) => item !== locator).sort()
    patchUpdate({ highlight_list: newHighlightList })
    setHighlightList(newHighlightList)
  }

  const addComment = async (locator, comment) => {
    const id = await postComment(locator, comment)
    const newCommentsList = { ...commentsList, [locator]: { comment, id } }
    setCommentsList(newCommentsList)
  }

  const removeComment = (locator) => {
    const commentId = commentsList[locator]?.id
    const newCommentsList = { ...commentsList }
    delete newCommentsList[locator]
    setCommentsList(newCommentsList)
    commentId && destroyComment(commentId)
  }

  const destroyComment = async (commentId) => {
    const currentUrl = window.location.href + '/comments/' + commentId
    try {
      const response = await getApi().delete(currentUrl)
      if (response.status !== 200) {
        console.error(
          'Deleting CandidateArticleComment failed with response',
          response,
        )
      }
      response?.data?.csrf_token && updateCsrfToken(response.data.csrf_token)
    } catch (error) {
      console.error('Error deleting CandidateArticleComment', error)
    }
  }

  const toggleFavorite = () => {
    const newIsFavorite = !isFavorite
    patchUpdate({ is_favorite: newIsFavorite })
    setIsFavorite(newIsFavorite)
  }

  const navigateToResourceCenter = () => {
    const articleLocation = window.location.pathname
    const resourceCenterLocation = articleLocation.replace(/\/article\/.*/, '')
    window.location.href = resourceCenterLocation
  }

  const navigateToGuides = () => {
    window.location.href = '/guides'
  }

  const notifyViewed = useCallback(async () => {
    if (hasNotifiedViewed) {
      return
    }
    setHasNotifiedViewed(true)
    const currentUrl = window.location.href
    const viewedUrl = currentUrl + '/viewed'
    try {
      const response = await getApi().post(viewedUrl)
      const success = response.status === 200
      if (!success) {
        console.error('Notifying viewed failed with response', response)
      }
    } catch (error) {
      console.error('Error notifying viewed', error)
    }
  }, [hasNotifiedViewed, setHasNotifiedViewed])

  const postComment = async (locator, comment) => {
    const currentUrl = window.location.href + '/comments'
    try {
      const response = await getApi().post(currentUrl, {
        candidate_article_comment: { locator, comment },
      })
      if (response.status !== 200) {
        console.error(
          'Adding CandidateArticleComment failed with response',
          response,
        )
      }
      response?.data?.csrf_token && updateCsrfToken(response.data.csrf_token)
      return response?.data?.id
    } catch (error) {
      console.error('Error adding CandidateArticleComment', error)
    }
  }

  const patchUpdate = async (data) => {
    const currentUrl = window.location.href
    try {
      const response = await getApi().patch(currentUrl, {
        candidate_article: data,
      })
      if (response.status !== 200) {
        console.error(
          'Updating CandidateArticle failed with response',
          response,
        )
      }
      response?.data?.csrf_token && updateCsrfToken(response.data.csrf_token)
    } catch (error) {
      console.error('Error updating CandidateArticle', error)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement
      const scrollPercent = Math.round(
        (scrollTop / (scrollHeight - clientHeight)) * 100,
      )
      const sectionNavNode = document.querySelector(
        'div[role="navigation"].section-nav',
      )
      if (sectionNavNode) {
        if (scrollTop > 79) {
          sectionNavNode.classList.add('hidden')
          !navTopScrolled && setNavTopScrolled(true)
        } else {
          sectionNavNode.classList.remove('hidden')
          navTopScrolled && setNavTopScrolled(false)
        }
      }
      if (scrollPercent > maxScrollDepth && scrollPercent <= 100) {
        setMaxScrollDepth(scrollPercent)
      }
      if (scrollPercent == 100 && !hasNotifiedViewed) {
        notifyViewed()
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [
    maxScrollDepth,
    notifyViewed,
    hasNotifiedViewed,
    setMaxScrollDepth,
    navTopScrolled,
  ])

  const handleResize = useCallback(() => {
    if (navPaddingRef.current && leftPanelRef.current) {
      navPaddingRef.current.style.width = `${
        leftPanelRef.current.clientWidth - 10
      }px`
    }
  }, [navPaddingRef, leftPanelRef])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  const value = {
    jsonContent,
    setJsonContent,
    sectionRef,
    jumpToSection,
    maxScrollDepth,
    hasNotifiedViewed,
    isHighlightModeActive,
    setIsHighlightModeActive,
    toggleHighlightMode,
    updateHighlightList,
    highlightList,
    isFavorite,
    toggleFavorite,
    toggleCommentMode,
    isCommentModeActive,
    setIsCommentModeActive,
    addComment,
    removeComment,
    commentsList,
    completionAnimationUrl,
    heroAwsUrl,
    navPaddingRef,
    leftPanelRef,
    navigateToResourceCenter,
    audioActive,
    toggleAudioActive,
    audioAwsUrl,
    cliftonStrengths,
    navigateToGuides,
  }

  return (
    <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>
  )
}
