import React from 'react'
import {
  ArticlesIcon,
  VideoIcon,
  ExercisesIcon,
  QuizzesIcon,
  AssessmentsIcon,
  WebinarsIcon,
  FavoriteIcon,
  WorkshopIcon,
} from './icons'
import { useResources } from './ResourcesProvider'

const NavHeader = () => {
  return (
    <div className="relative mb-8 w-full overflow-x-scroll border-b border-solid border-[#979797] sm:static sm:overflow-auto">
      <div className="flex flex-row justify-between gap-5 min-[530px]:justify-start min-[530px]:gap-10">
        <ArticleHeaderButton />
        <AudioHeaderButton />
        <QuizzesHeaderButton />
        <AssessmentsHeaderButton />
        <ExercisesHeaderButton />
        <WorkshopsHeaderButton />
        <FavoritesHeaderButton />
      </div>
    </div>
  )
}
export default NavHeader

const HeaderButton = ({ icon, title, onClick, active = false }) => {
  const activeClass = active ? 'w-full rounded-t-sm h-1 bg-primary' : 'h-1'
  return (
    <button onClick={onClick} className="flex min-w-16 flex-col items-center">
      {icon}
      <div className="my-2 whitespace-nowrap text-center text-sm text-black">
        {title}
      </div>
      <div className={activeClass}></div>
    </button>
  )
}

const ArticleHeaderButton = () => {
  const { openArticlesTab, articlesActive } = useResources()
  return (
    <HeaderButton
      icon={<ArticlesIcon />}
      onClick={openArticlesTab}
      title="Articles"
      active={articlesActive}
    />
  )
}

const AudioHeaderButton = () => {
  const { openAudioTab, audioActive } = useResources()
  return (
    <HeaderButton
      icon={<VideoIcon />}
      onClick={openAudioTab}
      title="Videos &amp; Audio"
      active={audioActive}
    />
  )
}

const ExercisesHeaderButton = () => {
  const { openExercisesTab, exercisesActive } = useResources()
  return (
    <HeaderButton
      icon={<ExercisesIcon />}
      onClick={openExercisesTab}
      title="Exercises"
      active={exercisesActive}
    />
  )
}

const QuizzesHeaderButton = () => {
  const { openQuizzesTab, quizzesActive } = useResources()
  return (
    <HeaderButton
      icon={<QuizzesIcon />}
      onClick={openQuizzesTab}
      title="Quizzes"
      active={quizzesActive}
    />
  )
}

const AssessmentsHeaderButton = () => {
  const { openAssessmentsTab, assessmentsActive } = useResources()
  return (
    <HeaderButton
      icon={<AssessmentsIcon />}
      onClick={openAssessmentsTab}
      title="Assessments"
      active={assessmentsActive}
    />
  )
}

const WebinarsHeaderButton = () => {
  const { openWebinarsTab, webinarsActive } = useResources()
  return (
    <HeaderButton
      icon={<WebinarsIcon />}
      onClick={openWebinarsTab}
      title="Webinars"
      active={webinarsActive}
    />
  )
}

const FavoritesHeaderButton = () => {
  const { openFavoritesTab, favoritesActive } = useResources()
  return (
    <HeaderButton
      icon={<FavoriteIcon />}
      onClick={openFavoritesTab}
      title="Favorites"
      active={favoritesActive}
    />
  )
}

const WorkshopsHeaderButton = () => {
  const { openWorkshopsTab, workshopsActive } = useResources()
  return (
    <HeaderButton
      icon={<WorkshopIcon />}
      onClick={openWorkshopsTab}
      title="Workshops"
      active={workshopsActive}
    />
  )
}
