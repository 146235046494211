import React, { useState } from 'react'
import { cn } from '../../lib/utils'
import Moment from 'moment'
import { Button } from '../../lib/ui/button'
import { useDashboard } from './DashboardProvider'
import {
  PencilSquareIcon,
  CheckBadgeIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline'
import SetReminderDialog from './SetReminder'
import axios from 'axios'

const Reminders = ({ className }) => {
  const { reminders, toggleShowAddReminderDialog, isMobile, setOnlyReminders } =
    useDashboard()
  return (
    <div
      className={cn(
        'relative bg-gradient-reminders p-4 md:col-span-3 md:h-full',
        className,
      )}
    >
      {isMobile && (
        <div className="absolute w-full">
          <div
            onClick={() => setOnlyReminders(false)}
            className="flex items-center text-xs"
          >
            <ChevronLeftIcon className="h-2 cursor-pointer" />
            <div>Back to Dashboard</div>
          </div>
        </div>
      )}
      <div className="mt-8 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="text-2xl font-bold text-intoo-blue-medium">
            Reminders
          </div>
          <div>
            Job search can be a stressful experience. Let us help you get
            started on planning your day to day.
          </div>
          <SetReminderDialog>
            <Button className="w-6/12" onClick={toggleShowAddReminderDialog}>
              + New Reminder{' '}
            </Button>
          </SetReminderDialog>
        </div>
        {reminders.map((reminder, idx) => (
          <ReminderTile
            key={`reminders-${idx}`}
            reminder={reminder}
          ></ReminderTile>
        ))}
      </div>
    </div>
  )
}
export default Reminders

const ReminderTile = ({ reminder }) => {
  const { setEditingReminder, setShowAddReminderDialog, setReminders } =
    useDashboard()
  const [reminderCompleted, setReminderCompleted] = useState(false)
  const [border, setBorder] = useState('border')
  const [transitionDuration, setTransitionDuration] =
    useState('duration-[2000ms]')
  const [divHeight, setDivHeight] = useState('max-h-full min-h-48')
  const reminderAnimation = reminderCompleted
    ? 'animate__animated animate__fadeOut'
    : ''
  const background = reminderCompleted
    ? 'bg-intoo-blue-medium bg-opacity-95'
    : 'bg-white'

  const dueDate = reminder.due_date
  const content = reminder.content

  const removeReminderFromList = (completedReminder) => {
    setReminders((prev) => prev.filter((r) => r.id !== completedReminder.id))
  }

  const completedReminderAnimation = (completedReminder) => {
    setReminderCompleted(true)
    setTimeout(() => {
      setBorder('')
      setDivHeight('h-0 min-h-0')
      setTransitionDuration('duration-[200ms]')
    }, 2000)
    setTimeout(() => {
      removeReminderFromList(completedReminder)
      setReminderCompleted(false)
      setDivHeight('max-h-full min-h-48')
      setTransitionDuration('')
      setBorder('border')
    }, 2200)
    setTimeout(() => {
      setTransitionDuration('duration-[2000ms]')
    }, 2550)
  }

  const handleCompleteReminder = async () => {
    const completedReminder = reminder
    const token = document.querySelector('meta[name="csrf-token"]').content
    const config = { 'content-type': 'application/json', 'X-CSRF-TOKEN': token }
    const res = await axios.get(`/reminders/${reminder.id}/complete`, config)
    if (res.status === 200) {
      completedReminderAnimation(completedReminder)
      return res
    } else {
      console.log(res)
      console.log(res.status)
      console.log(res.error)
      throw new Error('res not ok')
    }
  }

  const handleEditReminder = () => {
    setEditingReminder(reminder)
    setShowAddReminderDialog(true)
  }

  const convertFromNow = () => {
    return Moment(dueDate).calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
    })
  }

  const todayOrTomorrow = convertFromNow()

  const due = dueDate
    ? ['Today', 'Tomorrow'].includes(todayOrTomorrow)
      ? todayOrTomorrow
      : Moment(dueDate).format('M/D/YY')
    : 'Not Set'

  const borderColor = ['Today', 'Tomorrow'].includes(due)
    ? 'border-[#FF990059]'
    : 'border-[#D6D6D6]'

  return (
    <div
      id={`reminder-${reminder.id}`}
      className={cn(
        'flex-flex-col overflow-hidden rounded-[20px] border-solid bg-white shadow-dashboard-reminder-tile',
        borderColor,
        reminderAnimation,
        background,
        transitionDuration,
        divHeight,
        border,
      )}
    >
      <div className="flex items-center justify-between p-4">
        <div>Due Date: {due}</div>
        <PencilSquareIcon
          className="h-6 cursor-pointer"
          onClick={handleEditReminder}
        />
      </div>
      <hr />
      <div className="relative flex min-h-32 flex-col justify-between gap-2">
        <div className="p-4">{content}</div>
        {reminderCompleted && (
          <div className="absolute top-8 flex w-full justify-center">
            <CheckBadgeIcon className="h-20 text-white" />
          </div>
        )}
        {!reminderCompleted && (
          <div className="self-end p-4">
            <Button onClick={handleCompleteReminder}>Complete</Button>
          </div>
        )}
      </div>
    </div>
  )
}
