import React, { useState, createContext, useContext } from 'react'

const BenefitsContext = createContext()

export const useBenefits = () => {
  return useContext(BenefitsContext)
}

const BenefitsProvider = ({
  coach_calendly_url,
  candidate_name,
  candidate_email,
  candidate_id,
  coach_id,
  calendly_events_path,
  completed_calendly_event,
  pending_event,
  children,
}) => {
  const defaultCalendlyEvent = () => {
    if (completed_calendly_event) {
      return { type: 'completed', ...completed_calendly_event }
    } else if (pending_event) {
      return { type: 'pending', ...pending_event }
    } else {
      return null
    }
  }
  const [calendlyEvent, setCalendlyEvent] = useState(defaultCalendlyEvent())

  const values = {
    coach_calendly_url,
    candidate_name,
    candidate_email,
    candidate_id,
    coach_id,
    calendly_events_path,
    calendlyEvent,
    setCalendlyEvent,
  }
  return (
    <BenefitsContext.Provider value={values}>
      {children}
    </BenefitsContext.Provider>
  )
}
export default BenefitsProvider
