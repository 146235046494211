import * as React from 'react'

import { useJobSearch } from './JobSearchProvider'
import Sanitize from '../Sanitize'
import { LinkedInButton, ApplyNowButton, ApplyWithIntooButton } from './buttons'
import { useTranslation } from '../../../hooks/useTranslation'
import LeaveSiteWarning from '../../shared/LeaveSiteWarning'
import IndeedLogo from './IndeedLogo'
import SavedJobButton from './SavedJobButton'
import { useIsMobile } from '../../../hooks/useMediaQuery'
import CheckCircleIcon from './CheckCircleIcon'

export default function JobDetails() {
  return (
    <div className="flex flex-col gap-4">
      <JobDetailsTop />
      <JobDetailsBottom />
    </div>
  )
}

export function JobDetailsTop() {
  const {
    selectedJob: job,
    debug,
    queryStatusMessage,
    isIntooItaly,
    isDemo,
  } = useJobSearch()
  const { t } = useTranslation('JobDetails')
  const isMobile = useIsMobile()
  if (!job) return null

  return (
    <>
      <div className="relative flex flex-row justify-between">
        {!!job.posted_on_month_day && (
          <div className="text-xs">
            {t('posted')} {job.posted_on_month_day}
          </div>
        )}

        <div className="absolute right-0 top-0 mt-[-0.75rem]">
          <SavedJobButton job={job} variant="icon" includeText />
        </div>
      </div>

      {job?.job_bank_job && isIntooItaly && isDemo && (
        <div className="flex">
          <div
            className="rounded-md p-1.5 px-2.5 text-xs font-medium text-white"
            style={{ background: 'var(--theme-primary)' }}
          >
            {t('intoo_job_bank')}
          </div>
        </div>
      )}

      <div className="flex flex-col gap-1">
        <h2 className="text-xl font-extrabold">{job.title}</h2>

        <div className="flex items-center text-xs">
          <LeaveSiteWarning url={job.url}>
            <a className="">{job.company_name}</a>
          </LeaveSiteWarning>
          <span className="mx-2">•</span>
          <div>{job.location}</div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-start gap-3 sm:gap-4">
        {job.job_bank_job ? (
          job.job_bank_job_applied ? (
            <AppliedWithIntoo />
          ) : (
            <ApplyWithIntooButton job={job} />
          )
        ) : (
          <ApplyNowButton job={job} compact={isMobile} />
        )}
        <LinkedInButton job={job} compact={isMobile} />
        <SavedJobButton job={job} compact={isMobile} />
      </div>

      {debug && <pre className="break-all text-xs">{queryStatusMessage}</pre>}
    </>
  )
}

export function JobDetailsBottom() {
  const { selectedJob: job } = useJobSearch()

  if (!job) return null
  const isIndeed = job.job_publisher_name === 'Indeed'

  return (
    <div className="text-base">
      <Sanitize html={job.description} addMarkup />
      {isIndeed && job?.job_bank_job && (
        <LeaveSiteWarning url={job.url}>
          <div className="mb-4 mt-16 flex flex-row gap-1 text-sm text-intoo-blue-medium">
            {`jobs by`} <IndeedLogo className="-mt-1" />
          </div>
        </LeaveSiteWarning>
      )}
    </div>
  )
}

export function AppliedWithIntoo() {
  const { t } = useTranslation('ApplyWithIntooButton')
  return (
    <div
      className="flex cursor-default items-center gap-0"
      title={t('applied_with_intoo')}
    >
      <CheckCircleIcon />
      <span
        className="text-sm font-medium"
        style={{ color: 'var(--theme-secondary)' }}
      >
        {t('applied')}
      </span>
    </div>
  )
}
