import React from 'react'
import StartQuiz from './StartQuiz'
import Quiz from './Quiz'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import { QuizProvider, useQuiz } from './QuizProvider'
import { JobSearchQuizResults, PersonalBrandQuizResults } from './Results'

const Index = ({ ...props }) => {
  return (
    <QuizProvider {...props}>
      <QuizComponents {...props} />
    </QuizProvider>
  )
}
export default Index

const QuizComponents = ({ locale }) => {
  const { documentId, started, showResults, resultsTasks, quizName } = useQuiz()
  return (
    <TranslationProvider
      locale={locale || 'en'}
      translations={translations}
      scope={'javascript.components.Quizzes'}
    >
      <div className="quiz flex justify-center border-l border-[#DDD] px-4 py-6">
        {showResults && quizName === 'Job Search' && (
          <JobSearchQuizResults tasks={resultsTasks} />
        )}
        {showResults && quizName === 'Personal Brand Iq' && (
          <PersonalBrandQuizResults tasks={resultsTasks} />
        )}
        {!started && !showResults && <StartQuiz quizExists={!!documentId} />}
        {started && !showResults && <Quiz />}
      </div>
    </TranslationProvider>
  )
}
