import React from 'react'
import { FavoriteIcon, ArticlesIcon } from './icons'
import { useResources } from './ResourcesProvider'

const ArticleCard = ({ article }) => {
  const { headerImageUrl, headerImageAlt, title, description } = article
  const { checkFavorite, toggleFavorite, getArticlePath, isRecentlyAdded } =
    useResources()
  const articlePath = getArticlePath(article)

  const isFavorite = checkFavorite(article)
  const favoriteIconClass = isFavorite
    ? 'fill-primary stroke-primary'
    : 'stroke-primary'
  const favoriteTitle = isFavorite
    ? 'Remove from favorites'
    : 'Add to favorites'

  const recentlyAdded = isRecentlyAdded(article)

  return (
    <div className="relative mb-8 overflow-hidden rounded-3xl border border-solid border-[#B9B9B9]">
      {recentlyAdded && (
        <div
          className="absolute left-0 top-0 z-10 rounded-br-3xl px-7 py-2 text-sm font-bold text-white"
          style={{
            background: 'linear-gradient(90deg, #42A7FF 0%, #76C0FF 100%)',
          }}
        >
          Recently Added
        </div>
      )}
      <div className="absolute right-4 top-4">
        <button
          className="rounded-lg bg-white p-2 hover:bg-gray-100"
          title={favoriteTitle}
          onClick={() => toggleFavorite(article)}
        >
          <FavoriteIcon className={favoriteIconClass} height={24} />
        </button>
      </div>
      <img
        src={headerImageUrl}
        alt={headerImageAlt}
        className="aspect-[22/11] w-full object-cover object-center"
      />
      <div className="p-4">
        <h2
          role="heading"
          tabIndex="0"
          aria-level="2"
          className="!mb-2 !mt-0 truncate text-xl font-bold focus:overflow-visible"
        >
          {title}
        </h2>
        <p className="!my-0 line-clamp-3">{description}</p>
        <div className="mt-6">
          <a
            href={articlePath}
            className="flex w-full items-center justify-center rounded-3xl bg-primary px-4 py-3 text-base font-medium text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white sm:py-2"
          >
            Read Article
            <ArticlesIcon className="ml-2 stroke-white" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
