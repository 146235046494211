import React, { useRef, useContext, createContext, useState } from 'react'
import { useMediaQueryScreen } from '../../hooks/useMediaQuery'
import usePersistState, {
  usePersistRefreshState,
} from '../../hooks/usePersistState'

const NavContext = createContext()

export const useNav = () => {
  return useContext(NavContext)
}

export const NavProvider = ({
  menuItems,
  userName,
  startExpanded = true,
  isSilverOrAbove,
  isGoldOrAbove,
  isNotGlobalProgramLevel,
  goal,
  dynamicLinks,
  children,
}) => {
  const navItems = menuItems.navItems

  const navItemsArr = Object.values(navItems)

  const pathToSlugConversion = () => {
    let activeNavItemSlug
    let activeSubNavItemTitle
    const path = window.location.pathname

    const basePath = path.split('/').slice(0, -1).join('/')

    navItemsArr.forEach((navItem) => {
      const children = navItem.children

      children.forEach((item) => {
        if (basePath === '/coaching/resources/article')
          activeNavItemSlug = 'guidesMiniPlatform'
        else if (item.path === path) {
          activeNavItemSlug = item.slug
        }
        if (item.children) {
          item.children.forEach((subItem) => {
            if (subItem.path === path) {
              activeSubNavItemTitle = subItem.title
            }
          })
        }
      })
    })

    return { activeNavItemSlug, activeSubNavItemTitle }
  }

  const { activeNavItemSlug, activeSubNavItemTitle } = pathToSlugConversion()

  const [activeNav, setActiveNav] = usePersistRefreshState(
    activeNavItemSlug,
    'activeNav',
  )

  const [activeSubNav, setActiveSubNav] = useState(activeSubNavItemTitle)
  const isMobile = !useMediaQueryScreen('sm')
  const [isExpanded, setIsExpanded] = usePersistState(
    isMobile || startExpanded,
    'isExpanded',
  )
  const [popupSubNav, setPopupSubNav] = useState(null)
  const currentTimer = useRef(null)
  const showGoal = menuItems.showGoal === false ? false : true
  const showUser = menuItems.showUser === false ? false : true

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const navigateTo = (item) => {
    var href = getLink(item)
    if (href) {
      window.location.href = href
    }
  }

  const getLink = (item) => {
    const { dynamicLink, path } = item
    if (dynamicLink) {
      return dynamicLinks[dynamicLink]
    } else if (path) {
      return path
    }
  }

  const meetsMinimumProgramLevel = (programLevel) => {
    if (!programLevel) {
      return true
    }
    if (programLevel === 'silverOrAbove') {
      return isSilverOrAbove
    } else if (programLevel === 'goldOrAbove') {
      return isGoldOrAbove
    } else if (programLevel === 'notGlobalProgramLevel') {
      return isNotGlobalProgramLevel
    } else {
      console.log('program level not found', programLevel)
      return true
    }
  }

  const value = {
    navItems,
    userName,
    getLink,
    isExpanded,
    toggleExpanded,
    activeSubNav,
    setActiveSubNav,
    activeNav,
    setActiveNav,
    navigateTo,
    popupSubNav,
    setPopupSubNav,
    currentTimer,
    meetsMinimumProgramLevel,
    isMobile,
    goal,
    showGoal,
    showUser,
  }

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}
