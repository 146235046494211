import React from 'react'

import { CoachCard } from '../CoachCard'
import { PhoneIcon } from '@heroicons/react/24/outline'
import OrientationCallPopup from '../../OrientationCallPopup'

export function OrientationCallCard({ status }) {
  // TODO: Implement in_progress and completed (See ResumeReviewCard.js)

  return (
    <>
      <CoachCard
        icon={PhoneIcon}
        title="Orientation Call"
        description="Use this call to meet your coach and learn about the system and the services available to you."
      >
        <OrientationCallPopup />
      </CoachCard>
    </>
  )
}
