import React from 'react'

import LeaveSiteWarning from '../shared/LeaveSiteWarning'
import { useCalendlyEventListener, PopupButton } from 'react-calendly'
import { useBenefits } from './BenefitsProvider'
import { api } from '../../util/api'
import moment from 'moment'

const OrientationCallScheduled = ({ calendlyEvent }) => {
  const { type, start_time, join_url } = calendlyEvent
  return (
    <>
      {type === 'completed' && (
        <div className="font-bold">Orientation Call Completed</div>
      )}
      {type === 'pending' && (
        <div>
          <div className="font-bold">Orientation Call Scheduled</div>
          <div>{moment(start_time).format('MMMM Do YYYY, h:mm:ss a')}</div>
          <div className="pt-4">
            <a
              href={join_url}
              className="inline-flex h-10 items-center justify-center whitespace-nowrap rounded-full border border-cod-gray-700 bg-white px-8 py-2 text-base font-medium text-cod-gray-800 ring-offset-background transition-colors hover:bg-cod-gray-75 hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
            >
              Join Call
            </a>
          </div>
        </div>
      )}
    </>
  )
}

const OrientationCallPopup = ({ buttonText }) => {
  const {
    coach_calendly_url,
    candidate_name,
    candidate_email,
    candidate_id,
    coach_id,
    calendly_events_path,
    calendlyEvent,
    setCalendlyEvent,
  } = useBenefits()

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      const payload = e.data.payload
      createCalendlyEvent(
        payload.event.uri.split('/').pop(),
        payload.invitee.uri.split('/').pop(),
      )
    },
  })

  const createCalendlyEvent = async (calendlyId, calendlyUserId) => {
    const data = {
      request_format: 'json',
      calendly_event: {
        candidate_id: candidate_id,
        coach_id: coach_id,
        calendly_id: calendlyId,
        calendly_user_id: calendlyUserId,
        meeting_name: '60 minute orientation call',
      },
    }

    const res = await api.post(calendly_events_path, data)

    if (res.status === 200) {
      setTimeout(() => {
        const event = { type: 'pending', ...res.data.calendlyEvent }
        setCalendlyEvent(event)
      }, 2000)
    } else {
      throw new Error('Calendly event creation failed')
    }
  }

  if (calendlyEvent === null) {
    return (
      <PopupButton
        url={`${coach_calendly_url}/60min`}
        prefill={{
          name: candidate_name,
          email: candidate_email,
        }}
        className="h-10 rounded-full bg-intoo-green-coachcta px-8 py-2 font-medium text-white hover:bg-intoo-green-coachcta-hover"
        rootElement={document.getElementById('popupContainer')}
        text={buttonText || 'Schedule A Call'}
      />
    )
  } else {
    return <OrientationCallScheduled calendlyEvent={calendlyEvent} />
  }
}
export default OrientationCallPopup
